import { useEffect, useState } from "react";
import { array } from "prop-types";
import Container100 from "../Container/Container100.jsx";
import ResourceItem from "../ResourceItem/ResourceItem.jsx";
import * as R from "ramda";
import "./resource-listing.scss";
import Select from "../Select/Select.jsx";
import "aos/dist/aos.css";
import AOS from "aos";

const propTypes = {
  items: array,
  resourceCities: array,
  resourceTopics: array,
};

//title, description, fluid, link
const ResourceListing = (props) => {
  const { items } = props;
  const dictionary = {
    ContentfulCity: "All Cities",
    ContentfulTopic: "All Topics",
  };

  const [state, setState] = useState({
    results: items,
    activeResults: items,
    selected: [null, null],
  });

  const currentTags = R.compose(
    R.values,
    R.mapObjIndexed((i, k) => {
      return { tagGroup: i[0], tags: R.map((x) => ({ index: k, ...x }))(i[1]) };
    }),
    R.toPairs,
    R.map(R.groupBy(R.prop("__typename")), R.uniq),
    R.unnest,
    R.pluck("resourceTags"),
    R.filter((x) => x.resourceTags !== null)
  )(items);

  const includesTags = R.curry((tags, item) => {
    const itemTags = R.compose(R.pluck("id"), R.prop("resourceTags"))(item);
    //or R.any for OR
    return R.all(R.includes(R.__, itemTags), tags);
  });

  const handleChange = (changes, k) => {
    const changedSelect = state.selected;
    changedSelect[k] = changes.selectedItem.id;
    const newResults = R.compose(R.filter(includesTags(R.reject(R.isNil, changedSelect))))(
      state.results
    );

    setState({
      ...state,
      activeResults: newResults,
      selected: changedSelect,
    });
  };

  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 1000,
      once: true,
    });
  }, []);

  const mergeTag = (itemTags, currentTags) => {
    const ids = R.compose(R.map(R.prop("id")))(itemTags);
    const items = R.unnest(R.map(R.prop("tags"))(currentTags));
    return R.map((id) => R.find(R.propEq("id", id))(items))(ids);
  };

  return (
    <Container100>
      {currentTags && (
        <div className="resources__filters" data-aos="fade-up">
          {/* 
            Controlled select simply passes in State of select from external source
            TODO: better way to accomplish both controlled and non controlled selects within the same component?
             */}
          {currentTags.map((group, k) => (
            <Select
              key={k}
              updateBtnText={true}
              selectProps={{
                selectedItem: R.compose(
                  R.defaultTo({
                    id: null,
                    value: dictionary[group.tagGroup],
                  }),
                  R.last(),
                  R.map(
                    R.applySpec({
                      id: R.prop("id"),
                      value: R.prop("name"),
                    })
                  ),

                  R.filter(R.propEq("id", state.selected[k])),
                  R.prop("tags")
                )(currentTags[k]),
                items: [
                  {
                    id: null,
                    value: dictionary[group.tagGroup],
                  },
                  ...R.map((i) => ({ id: i.id, value: i.name }))(group.tags),
                ],
                itemToString: (d) =>
                  !R.isNil(d)
                    ? d.value
                    : () => ({
                        id: null,
                        value: dictionary[group.tagGroup],
                      }),
                initialSelectedItem: state.selected[k] || {
                  id: null,
                  value: dictionary[group.tagGroup],
                },
                onSelectedItemChange: (changes) => handleChange(changes, k),
              }}
            />
          ))}
        </div>
      )}
      <div className="resources__container">
        {state.activeResults &&
          state.activeResults.length > 0 &&
          state.activeResults.map((item, k) => (
            <ResourceItem
              key={k}
              fluid={item.featuredImage.fluid}
              fixed={item.featuredImage.fixed}
              title={item.title}
              description={item.description}
              link={item.link}
              resourceTags={
                item.resourceTags ? mergeTag(item.resourceTags, currentTags) : currentTags
              }
              handleChange={handleChange}
            />
          ))}

        {state.activeResults.length <= 0 && (
          <div className="resources__item resources__item--no-results">
            <h2>No Results</h2>
            <p>There are not results for the the selected facets above.</p>
          </div>
        )}
      </div>
    </Container100>
  );
};

ResourceListing.propTypes = propTypes;
export default ResourceListing;
