import { useEffect } from "react";
import * as R from "ramda";
// import { scrollToElement } from "../../common/scrollHelpers.js";
import GatsbyImage from "gatsby-image";
import ContentfulLink from "../ContentfulLink/ContentfulLink.jsx";
import { string, object, node, func } from "prop-types";
import "aos/dist/aos.css";
import AOS from "aos";
import cx from "classnames";

const defaultProps = {
  title: string,
  fluid: object,
  fixed: object,
  description: node,
  link: object,
  handleChange: func,
};

const ResourceItem = (props) => {
  const { title, description, fluid, handleChange, fixed, link, resourceTags } =
    props;
  const tags = R.compose(R.sortBy(R.prop("__typename")), R.uniq)(resourceTags);

  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div className="resources__item" data-aos="fade-up">
      {/* <GatsbyImage className="resources__item-img" fluid={fluid} /> */}
      <GatsbyImage
        className="resources__item-img resources__item-img--fixed"
        fixed={fixed}
      />

      {title && (
        <ContentfulLink {...link}>
          <h2 className="resources__item-title">{title}</h2>
        </ContentfulLink>
      )}
      {tags && (
        <div className="resources__tag-container">
          {tags.map((tag, i) => (
            <button
              key={`${i}-${JSON.stringify(tag.name)}`}
              className={cx("resources__tag", "btn", "btn--text")}
              onClick={(e) =>
                handleChange(
                  { selectedItem: { id: tag.id, value: tag.name } },
                  tag.index,
                )
              }
            >
              {tag.name}
            </button>
          ))}
        </div>
      )}
      {description && (
        <div className="resources__item-description">{description}</div>
      )}
      {link && <ContentfulLink {...link} className="btn btn--text" />}
    </div>
  );
};

ResourceItem.propTypes = defaultProps;
export default ResourceItem;
