import { useEffect, useRef } from "react";
import * as R from "ramda";
import { useStaticQuery, graphql } from "gatsby";
// import { scrollToElement } from "../../common/scrollHelpers.js";
import ResourceListing from "../ResourceListing/ResourceListing.jsx";
import "aos/dist/aos.css";
import AOS from "aos";

import {
  extractBanner,
  extractRichText,
  extractLink,
} from "../../common/contentful-extract.jsx";

import Banner from "../Banner/Banner.jsx";
// import Container100 from "../Container/Container100.jsx";
import Page from "../Page/Page.jsx";
import Section from "../Section/Section.jsx";

const ResourcePage = () => {
  const contentfulData = useStaticQuery(query);

  const contentRef = useRef();

  const data = R.compose(
    R.evolve({
      bottomBanner: extractBanner,
      resourceSubtitle: extractRichText,
      list: R.map(
        R.evolve({
          description: extractRichText,
          link: extractLink,
        }),
      ),
    }),
    R.defaultTo({}),
    R.prop("contentfulResourceListingPage"),
  )(contentfulData);

  const { bottomBanner, resourceTitle, resourceSubtitle, list } = data;

  useEffect(() => {
    // https://github.com/michalsnik/aos
    // initialize animation on scroll when the page loads
    AOS.init({
      offset: 100,
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <Page title="Resources">
      <Section
        aos={true}
        theme="light"
        title={resourceTitle}
        subtitle={resourceSubtitle}
        ref={contentRef}
        modifier={"section--spacing-sm"}
      >
        <ResourceListing items={list} />
      </Section>
      <Banner {...bottomBanner} aos={true} />
    </Page>
  );
};

export default ResourcePage;

const query = graphql`
  {
    # allContentfulResourceItem {
    #   nodes {
    #     title
    #     description {
    #       raw
    #     }
    #     featuredImage {
    #       fluid(maxWidth: 368, maxHeight: 368) {
    #         aspectRatio
    #         sizes
    #         src
    #         srcSet
    #         srcSetWebp
    #         srcWebp
    #         base64
    #       }
    #     }
    #     resourceTags {
    #       ... on ContentfulCity {
    #         id
    #         name
    #         slug
    #         __typename
    #       }
    #       ... on ContentfulTopic {
    #         id
    #         name
    #         slug
    #         __typename
    #       }
    #     }
    #     link {
    #       ...Link
    #     }
    #   }
    # }
    contentfulResourceListingPage {
      resourceSubtitle {
        raw
      }
      resourceTitle
      bottomBanner {
        backgroundImage {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          file {
            url
          }
        }
        backgroundSize
        textColor
        subtitle {
          raw
        }
        title {
          raw
        }
        content {
          raw
        }
        link {
          ...Link
        }
      }
      list {
        title
        description {
          raw
        }
        featuredImage {
          fluid(maxWidth: 368, maxHeight: 368) {
            aspectRatio
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
            base64
          }
          fixed(width: 368, height: 368, quality: 70) {
            src
            srcSet
            srcSetWebp
            srcWebp
            base64
          }
        }
        resourceTags {
          ... on ContentfulCity {
            id
            name
            slug
            __typename
          }
          ... on ContentfulTopic {
            id
            name
            slug
            __typename
          }
        }
        link {
          ...Link
        }
      }
    }
  }
`;
